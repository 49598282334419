
$theme-colors: (
    primary: #e32a31,
    secondary: #e32a31,
    black: #181617,
    white: #ffffff,
    red: #ad2c21,
    green: #8abe40,
    blue: #01a0e8
);
@function theme-color($name) {
    @return map-get($theme-colors, $name);
}