@import "variables";

/* Block editorial */
.editorial_block {
	background:theme-color(white);
	margin-top: 30px;
	margin-bottom: 30px;
	color: lighten(theme-color(black),30%);
	/*margin-left: -15px;
	margin-right: -15px;*/
}
#editorial_block_center .row{
	background: theme-color(white);
}

.editorial_block .col-sm-7{
	padding: 60px;
}

@media(max-width:768px){
	.editorial_block {
		margin-left: 0;
		margin-right: 0;
		padding-left: 15px;
		padding-right: 15px;
	}
	.editorial_block .col-sm-7{
		padding: 20px;
	}
}

.editorial_block h2{
	font-size: 20px;
	font-family: filson-pro, sans-serif;
    font-weight: 700;
}

.editorial_block h3{
	font-size: 16px;
	font-family: filson-pro, sans-serif;
    font-weight: 700;
}

@media(min-width:769px){
	#editorial_block_center .row{
		display: table;
	}
	#editorial_block_center .row .col-sm-7,
	#editorial_block_center .row .col-sm-5{
		display: table-cell;
		float: none !important;
		vertical-align: top !important;
	}
	
}